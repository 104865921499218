import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment';

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const token = JSON.parse(localStorage.getItem('user'))?.token
axios.defaults.headers.common.Authorization = `Bearer ${token}`

export const requests = {


    state: () => ({
        requestsList: [],
        permissionsList: [],
        updateRequestMessage: null,
        addedClientId: null,
        deleteRequestMessage: null,
        prioritiesList: [],
        naturesList: [],
        statusesList: [],
        verificationsList: [],
        createRequestMessage: null,
        selectedRequest: {},
        activeClients: [],
        requestsAnswer: null,
        answer: {},
        commentMessage: null,
        comments: [],
        reqPagination: null,
        reqPaginationData: null,
        reqCount: null,
        reqCountClient: null,
        reqCountClientWithAllStatuses: null,
        personLevel1: null,
        personLevel2: null,
        personLevel3: null,
        personLevel4: null,
        natureOrganization: null,
        natureFinancial: null,
        natureOther: null,
        logsList: null,
        allNotification:[],
        unReadNotification:[]
    }),

    mutations: {

        setRequestsList(state, data) {
            state.requestsList = data;
        },
        setReqPagination(state, data) {
            state.reqPagination = data;
        },
        setReqCount(state, data) {
            state.reqCount = data;
        },
        setReqCountClient(state, data) {
            state.reqCountClient = data;
        },
        setReqCountClientWithAllStatuses(state, data) {
            state.reqCountClientWithAllStatuses = data;
        },
        setPermissionsList(state, data) {
            state.permissionsList = data;
        },

        setDeleteRequest(state, data) {
            state.deleteRequestMessage = data;
        },
        setPrioritiesList(state, data) {
            state.prioritiesList = data;
        },
        setNaturesList(state, data) {
            state.naturesList = data;
        },
        setVerificationsList(state, data) {
            state.verificationsList = data;
        },
        setStatusesList(state, data) {
            state.statusesList = data;
        },
        filterStatusesList(state) {
            state.statusesList = state.statusesList.filter(item => item !== 'Closed')
        },
        setCreateRequest(state, data) {
            state.createRequestMessage = data;
        },
        setRequest(state, data) {
            state.selectedRequest = data;
        },
        setRequestUpdate(state, data) {
            state.updateRequestMessage = data;
        },
        setActiveClients(state, data) {
            state.activeClients = data;
        },
        setRequestAnswer(state, data) {
            state.requestsAnswer = data
        },

        setAnswer(state, data) {
            state.answer = data
        },

        setAnswerCurrent(state, data) {
            state.answer = data
        },
        setCommentSave(state, data) {
            state.commentMessage = data
        },
        setComments(state, data) {
            state.comments = data
        },
        setPersonLevel1(state, data) {
            state.personLevel1 = data
        },
        setPersonLevel2(state, data) {
            state.personLevel2 = data
        },
        setPersonLevel3(state, data) {
            state.personLevel3 = data
        },
        setPersonLevel4(state, data) {
            state.personLevel4 = data
        },
        setNatureOrganization(state, data) {
            state.natureOrganization = data
        },
        setNatureFinancial(state, data) {
            state.natureFinancial = data
        },
        setNatureOther(state, data) {
            state.natureOther = data
        },
        setLogsList(state,data) {
            state.logsList = data
        },
        setAllNotification(state, data) {
            state.allNotification = data
        },
        setUnReadNotification(state,data) {
            state.unReadNotification = data
        },
        setNotification(state,data) {
            state.unReadNotification = state.unReadNotification + 1
            state.allNotification.unshift(data)
        },
    },

    actions: {

        async getRequestsList({commit}, paginationData = {}) {
            let requestData = {
                page: paginationData.page,
                multipleFilter: {},
                filterByRelationEqual: {
                    relation : 'user',
                    client_id: paginationData?.selectedClient,
                },
                filter: {
                    status: paginationData.status
                },
                filterRequestsByManagerRole: {
                    manager_id: paginationData.managerId
                },

                sort: {
                    sort: paginationData.sort,
                    sortBy: paginationData.sortBy
                },
                filterByDate: {},
            };

            if (paginationData.request_date_from && !paginationData.request_date_to) {
                requestData.filterByDate.request_date_from = {
                    name: moment(paginationData?.request_date_from).format("YYYY-MM-DD"),
                    column: 'request_date',
                };
            }
            if (!paginationData.request_date_from && paginationData.request_date_to) {
                requestData.filterByDate.request_date_to = {
                    name: moment(paginationData?.request_date_to).format("YYYY-MM-DD"),
                    column: 'request_date',
                };
            }

            if (paginationData.request_date_from && paginationData.request_date_to) {
                requestData.filterByDate.request_date_from = {
                    name: moment(paginationData?.request_date_from).format("YYYY-MM-DD"),
                    column: 'request_date',
                };
                requestData.filterByDate.request_date_to = {
                    name: moment(paginationData?.request_date_to).format("YYYY-MM-DD"),
                    column: 'request_date',
                };
            }
            requestData.multipleFilter.val = paginationData.title ?? '';
            requestData.multipleFilter.fields = [
                {
                    column: "title",
                    type: "custom"
                },
                {
                    column: 'description',
                    type: "relation",
                    relation: "answer"
                }
            ];

            return axios({
                method: 'get',
                url: '/requests/list',
                params: requestData
            })
                .then((data) => {
                    commit('setRequestsList', data.data.data)
                    commit('setReqPagination', data.data.meta)
                    commit('setReqCount', data.data.meta.total)
                    const arr = []
                    data.data.data.map(item => {
                        if (item.client_status !== 0) {
                            arr.push(item)
                        }
                    })
                    return arr
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getPermissionsList({commit}) {
            return await axios
                .get(`/permissions/list`)
                .then((data) => {
                    commit('setPermissionsList', data.data.data)
                    return data.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getRequest({commit}, id) {
            return await axios
                .get(`/requests/${id}/get`)
                .then((data) => {
                    commit('setRequest', data.data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getPersonLevel1({commit}, data) {


            return await axios
                .post('/requests/natureCount', data)
                .then((data) => {
                    commit('setPersonLevel1', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getPersonLevel2({commit}, data) {
            return await axios
                .post('/requests/natureCount', data)
                .then((data) => {
                    commit('setPersonLevel2', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getPersonLevel3({commit}, data) {
            return await axios
                .post('/requests/natureCount', data)
                .then((data) => {
                    commit('setPersonLevel3', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getPersonLevel4({commit}, data) {
            return await axios
                .post('/requests/natureCount', data)
                .then((data) => {
                    commit('setPersonLevel4', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getNatureOrganization({commit}, data) {
           return axios
               .post('/requests/natureCount', data)
                .then((data) => {
                    commit('setNatureOrganization', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getNatureFinancial({commit}, data) {
            return await axios
                .post('/requests/natureCount', data)
                .then((data) => {
                    commit('setNatureFinancial', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getNatureOther({commit}, data) {
            return await axios
                .post('/requests/natureCount', data)
                .then((data) => {
                    commit('setNatureOther', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getRequestUpdate({commit}, data) {
            return await axios
                .post(`/requests/${data.id}/update`, data)
                .then((data) => {
                    commit('setRequestUpdate', data)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getClientRequests({commit}, paginationData = {}) {

            let requestData = {
                page: paginationData.page,
                multipleFilter: {},
                filter: {status: paginationData.status},
                sort: {
                    sort: paginationData.sort,
                    sortBy: paginationData.sortBy
                },

            };
                requestData.multipleFilter.val = paginationData.title ?? '';
                requestData.multipleFilter.fields = [
                    {
                        column: "title",
                        type: "custom"
                    },
                    {
                        column: "description",
                        type: "custom"
                    },
                    {
                        column: 'description',
                        type: "relation",
                        relation: "answer"
                    }
                ];

            return await axios({
                method: 'get',
                url: `requests/clients`,
                params: requestData,

            })
                .then((data) => {
                    commit('setRequestsList', data.data.data)
                    commit('setReqPagination', data.data.meta)
                    commit('setReqCountClient', data.data.meta.total)
                    return data.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getClientRequestsWithAllStatuses({commit}) {

            return await axios({
                method: 'get',
                url: `requests/clients/all/statuses`,

            })
                .then((data) => {
                    commit('setReqCountClientWithAllStatuses', data.data.meta.total)
                    return data.data.meta.total
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getActiveClients({commit}, id) {
            return await axios
                .get(`requests/${id}/active/clients`)
                .then((data) => {
                    commit('setActiveClients', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },


        async getDeleteRequest({commit}, id) {
            return await axios
                .delete(`/requests/${id}/delete`)
                .then((response) => {
                    commit('setDeleteRequest', response)
                    return response.data
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getPrioritiesList({commit}) {
            return await axios
                .get(`/requests/priorities/list`)
                .then((data) => {
                    commit('setPrioritiesList', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getVerificationsList({commit}) {
            return await axios
                .get(`/requests/verifications/list`)
                .then((data) => {
                    commit('setVerificationsList', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getNaturesList({commit}) {
            return await axios
                .get(`/requests/natures/list`)
                .then((data) => {
                    commit('setNaturesList', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getStatusesList({commit}) {
            return await axios
                .get(`/requests/statuses/list`)
                .then((data) => {
                    commit('setStatusesList', data.data)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getCreateRequest({commit}, data) {
            return await axios
                .post(`/requests/create`, data)
                .then((res) => {
                    commit('setCreateRequest', res)
                    return res.data
                })
                .catch(err => {
                    return err.response.data
                })
        },

        async createRequestAnswer({commit}, data) {
            return await axios
                .post(`/requests/answer/create`, data)
                .then((data) => {
                    commit('setRequestAnswer', data.data.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async updateRequestAnswer({commit}, payload) {
            return await axios
                .post(`/requests/answer/${payload.id}/update`, payload.data)
                .then((data) => {
                    commit('setRequestAnswer', data.data.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },


        async getAnswerCurrent({commit}, id) {
            return await axios
                .get(`/requests/${id}/currentAnswer`)
                .then((data) => {
                    commit('setAnswerCurrent', data.data)
                })
        },

        async getCommentSave({commit}, data) {
            return await axios
                .post('/requests/comment/create', data)
                .then(({data}) => {
                    commit('setCommentSave', data)
                    return data
                })
        },

        async getComments({commit}, id) {
            return await axios
                .get(`/requests/${id}/currentComment`)
                .then(data => {
                    commit('setComments', data.data)
                })
        },
        async getLogsList({commit}, paginationData={}) {
            let requestData = {
                page: paginationData.page,

                sort: {
                    sort: paginationData.sort,
                    sortBy: paginationData.sortBy
                },
                multipleFilter: {},
                filterByStatus: {}
            };
            return await axios({
                method:'get',
                url:'/tracking/log/list',
                params: requestData
            })
                .then(({data}) => {
                    commit('setLogsList', data.data)
                    commit('setReqPagination', data.meta)
                    return data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async getNotification({commit}){
            return await axios
                .get(`/users/notifications`)
                .then((data) => {
                    commit("setAllNotification",data.data)
                    console.log(data.data);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        async markAsReadNotification({dispatch}, id){
            return await axios
                .get(`/users/notifications/as-read/${id}`)
                .then(() => {
                    dispatch('getNotification')
                    dispatch('getUnReadNotificationCount')
                })
                .catch(err => {
                    console.log(err);
                })
        },
        async markAllAsReadNotification({dispatch}){
            return await axios
                .get(`/users/notifications/all-as-read`)
                .then(() => {
                    dispatch('getNotification')
                    dispatch('getUnReadNotificationCount')

                })
                .catch(err => {
                    console.log(err);
                })
        },
        async getUnReadNotificationCount({commit}){
            return await axios
                .get(`/users/unread-notifications-count`)
                .then(data => {
                    commit("setUnReadNotification" ,data.data)
                })
                .catch(err => {
                    console.log(err);
                })
        }
    },
    getters: {
        requestsList: state => state.requestsList,
        permissionsList: state => state.permissionsList,
        selectedRequest: state => state.selectedRequest,
        addedClientId: state => state.addedClientId,
        prioritiesList: state => state.prioritiesList,
        verificationsList: state => state.verificationsList,
        naturesList: state => state.naturesList,
        statusesList: state => state.statusesList,
        activeClients: state => state.activeClients,
        requestsAnswer: state => state.requestsAnswer,
        answer: state => state.answer,
        comments: state => state.comments,
        reqPagination: state => state.reqPagination,
        reqCount: state => state.reqCount,
        reqCountClient: state => state.reqCountClient,
        reqCountClientWithAllStatuses: state => state.reqCountClientWithAllStatuses,
        personLevel1: state => state.personLevel1,
        personLevel2: state => state.personLevel2,
        personLevel3: state => state.personLevel3,
        personLevel4: state => state.personLevel4,
        natureOrganization: state => state.natureOrganization,
        natureFinancial: state => state.natureFinancial,
        natureOther: state => state.natureOther,
        logsList: state => state.logsList,
        allNotification: state => state.allNotification,
        unReadNotifications: state => state.unReadNotification
    }
}


