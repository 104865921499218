<template>
    <div style="background: white">
        <SideBar/>
        <div style="width: 100%;">
            <NavBar/>
            <router-view />
        </div>
    </div>
</template>

<script>
    import SideBar from "../components/app/SideBar";
    import NavBar from "../components/app/NavBar"
    export default {
        name: 'main-layout',
        components: {
            SideBar,
            NavBar
        }
    }
</script>

<style scoped lang="scss">

</style>