<template>
    <div class="lang-changer">
        <div id="customStyle"></div>
        <br><br>
        <span><flag :iso="selectedFlag" /></span>
            <md-field class="lang-field">
                <md-select
                    name="selectedLang"
                    v-model="selectedLang"
                    @md-selected="change"
                    class="lang"
                    @click="setStyles"
                >
                    <md-option  v-for="(lang, i) in langs"
                                id="lang-options"
                                :key="i"
                                :class="{'lang-active': lang.key === $i18n.locale }"
                                :selected="selectedLang === lang.key"
                                :value="lang.key"
                    >
                        &nbsp;<flag :iso="lang.iso" />
                    </md-option>
                </md-select>
            </md-field>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'lang-changer',
    data: () => ({
        selectedLang: '',
        selectedFlag: '',
        flags: {
            ru: 'ru',
            en: 'us'
        },
        langs: [
            {name: 'РУ', key: 'ru', iso: 'ru'},
            {name: 'ENG', key: 'en',iso: 'us'},
        ],
        customStyles: '<style>.md-menu-content.md-select-menu {width: 112px;left: 177px;}</style>'
    }),

    computed: {
        ...mapGetters(["userData"]),
    },

    async mounted() {
        this.$i18n.locale = this.userData.language
        if (this.userData.language === 'ru'){
            this.selectedFlag = 'ru'
        }
        if (this.userData.language === 'en'){
            this.selectedFlag = 'us'
        }
    },


    methods: {
        ...mapActions(['getUserInfo']),
       async change(key) {
            console.log(key,'key')
            this.$i18n.locale = key
            this.selectedLang = key
            this.selectedFlag = this.flags[key]
            const data = {
                name: this.userData.name,
                email: this.userData.email,
                role_id: this.userData.roles[0].id,
                language: key,
                onlyLanguage: true,
            }
            await this.$axios
                .post(process.env.VUE_APP_API_URL + `/users/${this.userData.id}/update`, data)
                .then(response => {
                    this.languages = response.data;
                    const user = JSON.parse(localStorage.getItem('user'))
                    user.user.language = key
                    localStorage.setItem('user',  JSON.stringify(user))
                    this.getUserInfo()
                    console.log(this.userData,'userData')

                })
                .catch(error => console.log('error', error));

        },

        setStyles () {
            const customStyle = document.getElementById('customStyle');
            customStyle.innerHTML = this.customStyles
        }

    }
}
</script>

<style lang="scss" >
    .lang-changer {
        width: 60px;
        margin-top: -60px;
        #customStyle {
            visibility: hidden;
        }
        .md-menu-content.md-select-menu {
            width: 112px!important;
            left: 1700px!important;
        }

        .lang {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            cursor: pointer;

            //&:first-child {
            //    border-right: 1px solid #000000;
            //    margin-right: 5px;
            //}

            &-active {
                color: #0045FF;
            }
        }
        .flag-icon{
            position: absolute;
            margin-top: 32px;
            margin-left: 10px;
        }
        .md-field{
            margin: 4px 0 69px;
        }
    }

</style>
