<template>
  <div class="navbar-wrap">
    <notification-header/>
    <div @click="hideOrShow" @blur="close" class="arrow-btn navbar-button-wrap">
      <img src="../../assets/images/person.png" class="user-img" alt="">
      <p class="user-name">{{ user.name }}</p>
      <div class="drop-down">
        <button class="arrow-btn">
          <img :class="isOpen ? 'arrow-down' : ''" style="margin-left: 11px" src="../../assets/images/arrow-down.png"
               alt="">
          <div :class="isOpen ? 'show' : 'hide'">

            <div class="language_block">
              <div class="logout" @click.stop="logoutHandler">
                  <p>{{ $t('logout') }}</p>
<!--                <p>Logout</p>-->
              </div>
            </div>

          </div>
        </button>
      </div>
    </div>
      <lang-changer class="menu-item_mobile"></lang-changer>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import LangChanger from '../lang-changer'
import NotificationHeader from "@/components/notification/Notification";

export default {
  name: 'nav-bar',
    components: {
      NotificationHeader,
        LangChanger
    },
  data: () => ({
    isOpen: false,
    user: {},
  }),
  computed: {
    ...mapGetters(['userData']),

  },
  async mounted() {
    await this.getUserInfo()
    this.user = this.userData
  },
  methods: {
    ...mapActions(['logout', 'getUserInfo']),
    ...mapMutations(['setRequestsList']),
    hideOrShow() {
      this.isOpen = !this.isOpen
    },
    logoutHandler() {
      this.setRequestsList([]);
      this.logout()
    },
    close() {
      this.isOpen = false
    },

  }
}
</script>

<style scoped lang="scss">

.arrow-btn {
  position: relative;
  border: none;
  outline: none;
  background-color: inherit;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  pre {
    position: absolute;
  }
}

.arrow-down {
  transform: rotate(180deg);
  margin-top: 12px!important;
}


.hide {
  display: none;
}

.show {
  display: block;
}

.drop-down {
  margin-top: -4px;
  height: 30px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  pre:hover {
    cursor: pointer;
  }
}

.navbar-button-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }
}


.navbar-wrap {
  display: flex;
  justify-content: flex-end;
  margin-right: 55px;
  margin-top: 10px;

  .user-img {
    margin: 0 7px 0 23px;
  }

  .user-name {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .show {
    margin-left: -160px;
    margin-top: 25px;
    display: flex;
  }
  .language_block{
    border: 1px solid #cdd0d9;
    border-radius: 0px 6px 6px 0px;
    position: relative!important;
    height: 54px;

  }

  .logout {
    text-align: center;
  }

  .language_block:hover {
    background: #054F63;
  }

  .language:hover {
    background: #054F63;
  }

  .language:hover .languages {
    display: block !important;
    color: #000000;
  }

  .language_block:hover p {
    color: white;
  }

  .language:hover .en p {
    color: #000000;
  }
  .language:hover .ru p {
    color: #000000;
  }

  .en {
    border-radius: 6px 0px 0px 0px !important;
    height: 55px!important;
  }
  .ru {
    border-radius: 0px 0px 0px 6px !important;
    height: 53px!important;

  }

  .en:hover p {
    color: #FFFFFF !important;
  }
  .ru:hover p {
    color: #FFFFFF !important;
  }

  .en:hover {
    background: #054F63!important;
  }
  .ru:hover {
    background: #054F63!important;
  }

  .logout p {
    font-size: 16px;
    color: #000000;
    margin-right: 115px;
    margin-top: 15px;
    padding-left: 15px;
  }

  .logout:hover p {
    color: white;
  }

  .language:hover span {
    filter: none;
  }

  .language {
    text-align: center;
    padding: 10px;
    background: #FFFFFF;
    border-radius: 0px 6px 0px 0px;

    color: #000000;
    height: 54px;
    display: flex;
    position: relative;
  }

  .language p {
    font-size: 16px;
    color: #000000;
    height: 24px;
    margin-right: 75px;
    margin-top: 7px;

  }

  .lang-slack {
    margin-top: 9px;
    filter: invert(100%);
  }

  .languages {
    display: none;
    position: absolute;
    top: -2px;
    right: 189px;
    border-radius: 6px 0px 0px 6px !important;
    border: 1px solid #cdd0d9;
    border-right: none;
  }

}
</style>