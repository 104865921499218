export default {
    users: 'Пользователи',
    email: 'Электронная почта',
    role: 'Роль',
    delete: 'Удалить',
    create: 'Создать',
    actions: 'Действия',
    userHeadData:  [
        {title: 'Имя', name: 'name', asc: false, desc: true},
        {title: 'Роль', name: 'role_name', asc: false, desc: true},
        {title: 'Электронная почта', name: 'email', asc: false, desc: true},
        {title: 'Создано в', name: 'created_at', asc: false, desc: true},
        {title: 'Обновлено в', name: 'updated_at', asc: false, desc: true},
    ],
    language: 'Язык',
    clientUser: 'Клиент',
    client: 'Клиент',
    save: 'Сохранять',
    cancel: 'Отмена',
    noPermission: 'Нет разрешения',
    createUser:  'Создать пользователя',
    editUser: 'Изменить пользователя',
    permissions: 'Разрешения',
    users_view: 'просмотр_пользователей',
    users_create: 'создать_пользователей',
    users_update: 'редактировать_пользователей',
    users_delete: 'удалить_пользователей',
    user_cabinet_view: 'просмотр_кабинета_пользователя',
    user_cabinet_update: 'редактировать_кабинета_пользователя',
    roles_view: 'просмотрь_роль',
    roles_create: 'созадть_роль',
    roles_update: 'редактировать_роль',
    roles_delete: 'удалить_роль',
    requests_view: 'запросы_просмотр',
    requests_create: 'запросы_созадть',
    requests_update: 'запросы_редактировать',
    requests_delete: 'запросы_удалить',
    client_create: 'клиент_созадть',
    client_update: 'клиент_редактироват',
    client_delete: 'клиент_удалить',
    requests_comment_view: 'запросы_комментарий_просмотр',
    requests_comment_create: 'запросы_комментарий_созадть',
    requests_comment_update: 'запросы_комментарий_редактировать',
    requests_comment_delete: 'запросы_комментарий_удалить',
    requests_answer_view: 'запросы_ответ_удалить',
    requests_answer_create: 'запросы_ответ_созадть',
    requests_answer_update: 'запросы_ответ_редактировать',
    requests_answer_delete: 'запросы_ответ_удалить',
    document_view: 'документ_смотреть',
    document_create: 'документ_созадть',
    document_update: 'документ_редактировать',
    document_delete: 'документ_удалить',
    clients_view: 'клиент_смотреть',
    "A user with this Name already exists in the database." : 'Пользователь с таким именем уже существует в базе данных.',
    "The email must be a valid email address." : 'Электронная почта должна быть действительным адресом электронной почты.',
}