<template>
  <div class="notification" >
    <div class="notification-icon" @click="showNotification">
      <img src="../../assets/images/notification.png">
      <div v-if="unReadNotifications != 0">{{ unReadNotifications}}</div>
    </div>
    <div v-if="showNotifications" v-click-outside="onClickOutside" class="notification-content" :class="{scrollContent:allNotification.length >= 10}">
      <div class="notification-title">
        <h1>Notifications</h1>
        <button @click="markAllAsReadNotification">Mark all as read</button>
        <img src="../../assets/images/beak-top.png" />
      </div>
      <div class="items">
        <div class="item" v-for="item in allNotification" :key="item.id">
          <div @click="redirectNotification(item)">
            <div class="item-info" >
              <div class="info-item-requestChange">
                <div class="unreadNothification-icon" v-if="item.read_at === null">
                  <img src="../../assets/images/unreadNothification.png">
                </div>
                <p :class="{'read-notification' :item.read_at !== null }">{{item.data.author.name}} {{item.data.subject}}</p>
                  <p class="info-item-requestChange-date">{{notificationsDate(item) }}</p>
              </div>
              <div class="item-info-requestName">
                <p :class="{'read-notification' :item.read_at !== null }">{{item.data.title}}</p>
              </div>
            </div>
          </div>
          <div class="item-mark-read" v-if="item.read_at === null">
            <p @click="markAsRead(item.id)">Mark as read</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import vClickOutside from 'v-click-outside'
import {mapGetters, mapActions,mapMutations} from "vuex";
export default {
  name: "notification-header",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      showNotifications:false,
    }
  },
  async mounted() {
    await this.notification()
    await this.getNotification()
    await this.getUnReadNotificationCount()
  },
  computed:{
    ...mapGetters(['userData','allNotification','unReadNotifications']),
  },
  methods:{
    ...mapActions(['getNotification','markAsReadNotification','markAllAsReadNotification','getUnReadNotificationCount']),
    ...mapMutations(['setNotification']),
     markAsRead(id) {
       this.markAsReadNotification(id)
    },
    showNotification(){
      this.showNotifications = true
    },
    onClickOutside() {
      this.showNotifications = false
    },
    notification(){
      this.userData.roles.map(() => {
          window.channel = window.pusher.subscribe("notification-send." + this.userData.id);
          window.channel.bind("new-notification-send", (data) => {
            this.setNotification(data)
          });
      })
    },
    notificationsDate(item) {
      return this.$moment(item.created_at).format('YYYY.MM.DD HH:mm')
    },
    async redirectNotification(item) {
      await this.markAsReadNotification(item.id)

      if (item.data.title === "Reset Password Notification") {
        return ""
      }
      if (this.$route.params?.id == item.data.request_id) {
        this.$router.go({name: 'requests-form-edit', params: {id: item.data.request_id}})
      }

      this.$router.push({name: 'requests-form-edit', params: {id: item.data.request_id}})
    }
  }
}
</script>

<style scoped>
.notification {
  z-index: 99999;
  position: relative;
}
.notification-icon {
  position: relative;
  display: flex;
  justify-content: end;
}
.notification-icon div{
  background: #FF4646;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  color: #FFFFFF;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -5px;
  right: -5px;
}
.notification-content {
  width: 523px;
  max-height: 677px;
  background: #FFFFFF;
  border: 1px solid #CDD0D9;
  border-radius: 4px;
  margin-top: 15px;
  position: absolute;
  right: 0px;
}
.notification-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
}
.notification-title h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}
.notification-title button {
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #054F63;
  cursor: pointer;
}
.notification-title img {
  position: absolute;
  right: 10px;
  top: -9px;
}
.items{
  padding: 20px 20px 0;
}
.item{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
}
.item a {
  text-decoration: none !important;
}
.item:hover .item-mark-read {
  display: block;
}
.info-item-requestChange{
  display: flex;
  align-items: center;
}
.info-item-requestChange p{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #000000;
}
.info-item-requestChange-date{
  color: #A9A9A9 !important;
  margin-left: 7px;
}
.item-info-requestName {}
.item-info-requestName p{
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}
.item-mark-read {
  display: none;
}
.item-mark-read p{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #054F63;
}
.unreadNothification-icon {
  margin-right: 10px;
}
.read-notification {
  color: #A9A9A9 !important;
}
.scrollContent {
  overflow-y: scroll;
}
</style>