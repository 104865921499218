import Vue from 'vue';
import Vuex from 'vuex';
import {requests} from "@/store/requests";
import {auth} from "@/store/auth";
import {users} from "@/store/users";
import {roles} from "@/store/roles";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        requests,
        auth,
        users,
        roles,
    }
})

