<template>
    <div class="sideBar">
        <img src="../../assets/images/logoTDD.png" alt="" class="logo">

        <div class="single-item" v-if="showBackButton">
            <a @click="back" class="go-back">
                <img src="../../assets/images/arrow-left.png" alt="arrow">
                {{ $t('back') }} {{ backTo() }}
            </a>
        </div>
        <!--on create page-->
        <div class="single-item item" v-if="showBackButton && onPage().includes('create')">
            <span>{{ $t('create') }} {{ getSingular() }}</span>
        </div>
        <!--on edit page-->
        <div class="single-item item" v-if="showBackButton && onPage().includes('edit')">
            <span>{{ $t('edit') }} {{ getSingular() }}</span>
        </div>
        <div class="side-items" v-if="!showBackButton">
            <div>
                <div
                      v-for="(menuItem, i) in menu"
                      :key="i"
                >
                  <template v-if="menuItem.name == 'Logs' && getroleName != 'admin'" >
                  </template>
                  <template v-else>
                    <div
                        class="single-item"
                        :class="{'side-item-active': menuItem.isActive}"
                        @click="selectReport(menuItem)"
                    >
                      <router-link v-if="menuItem.path !== '/reportsData'" :to='menuItem.path' :class="menuItem.class">
                        <span>{{ menuItem.name }}</span>
                      </router-link>
                      <span v-if="menuItem.path === '/reportsData'">{{ menuItem.name }}</span>
                    </div>

                    <div v-if="menuItem.path === '/reportsData'" >
                      <div
                          v-for="subItem in menuItem.data"
                          :key="subItem.path"

                      >
                        <router-link :to='subItem.path'>
                          <span>{{ subItem.name }}</span>
                        </router-link>
                      </div>
                    </div>
                  </template>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {userPermissions} from '@/mixins/userPermissions';

    export default {
        data: () => ({
            clients: null,
            isOpenList: false,
            isOpenChildList: false,
            activeSubItem: '',
            user: {},

        }),
        mixins: [
          userPermissions
        ],
        computed: {
            ...mapGetters(['clientsList','userData']),
            showBackButton() {
              return this.$route.path.includes('edit') || this.$route.path.includes('create')
            },
            menu() {
              if(this.userData) {
                return this.$t('menuData').filter(menuItem => {
                  return this.userPermissions.indexOf(menuItem.permissionName) > -1
                })
              } else {
                return []
              }
            },
          getroleName(){
            let name= ""
            this.userData.roles.map(item => {
              name = item.name
            })
            return name
          }
        },

        watch: {
            '$route.path': {
                handler() {
                    this.activeItem();
                }
            },
            userData: {
                deep: true,
                handler () {
                    if (this.$t('menuData').length === 4) {
                        if (this.userData.roles[0].name === 'admin' || this.userData.roles[0].name === 'manager') {
                            this.$t('menuData').push({name: this.$t('reports'), path: '/reportsData', isActive: false, permissionName: 'requests_view'})
                        }
                    }
                    if (this.userData.roles[0].name === 'client') {
                        this.$t('menuData').findIndex(element => {
                            if (element.name ===  this.$t('reports')) {
                                this.$t('menuData').pop()
                            }
                        });
                    }
                }
            },

        },

        async mounted() {
           await this.getUserInfo().then( () => {
               this.user = this.userData
           });
           await this.getClientsList();
           this.activeItem();

                if (this.userData.roles[0].name === 'client') {
                     this.$t('menuData').findIndex(element => {
                        if (element.name ===  this.$t('reports')) {
                            this.$t('menuData').pop()
                        }
                    });
                }
        },

        methods: {
            ...mapActions(['getUserInfo', 'getClientsList']),

            selectReport(menuItem){
                this.menu.map(el => {
                    el.isActive = false
                })
                menuItem.isActive = true
                this.$route.path
                if(menuItem.path === '/reportsData'){
                    const index = this.$t('menuData').indexOf(this.$t('subMenuData'));
                    if (index === -1) {
                        this.$t('menuData').push(this.$t('subMenuData'))
                    }
                }else if(menuItem.path !== '/reportsData' &&  menuItem.path !== '/reports'){
                    const index = this.$t('menuData').indexOf(this.$t('subMenuData'));
                    if (index > -1) {
                        this.$t('menuData').splice(index, 1);
                    }
                }
            },

            activeItem() {
                this.menu.map(el => {
                    el.isActive = this.$route.path.includes(el.path)
                })
            },
            back() {
              let previousRoute = '';

              const routerStack = this.$router.history.stack;
              const idx = this.$router.history.index;

              if (idx > 0) {
                previousRoute = routerStack[idx - 1].name;
              }

              if(previousRoute.length <= 0) {
                let str = this.$route.path.split(this.onPage(), 1)[0].substring(1)

                previousRoute = '/'+str.slice(str.lastIndexOf('/') + 1)
              } else {
                previousRoute = '/'
              }

              this.$router.push(previousRoute)
            },
            onPage() {
                if (this.$route.path.includes('edit')) return '/edit';
                else if (this.$route.path.includes('create')) return '/create';
                return '';
            },
            backTo() {
                let str = this.$route.path.split(this.onPage(), 1)[0].substring(1)
                return this.$t(str.slice(str.lastIndexOf('/') + 1));
            },
            getSingular() {
                if (this.$i18n.locale === 'ru'){
                    switch (this.backTo()) {
                        case 'Ролам':
                            return 'Роль';
                        case 'Пользователям':
                            return 'пользователя';
                        case 'Клиентам':
                            return 'клиента';
                            default:
                                return this.backTo().slice(0, -2);
                    }
                }
                else {
                    return this.backTo().slice(0, -1);
                }

            }
        }
    }
</script>

<style scoped lang="scss">

    ul li {
        list-style-type: none;
    }

    .sideBar {
        width: 22%;
        min-width: 205px;
        background: #F0F5F7;

        .logo {
            width: 144px;
            margin: 20px 0 34px 29px;
        }

        span {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
        }


    }

    .list-item {
        margin-left: 5px;
    }

    .sub-list {
        margin-left: 10px;
    }
    .single-item {
       cursor: pointer;
    }

    .sub-item {
        margin: 0 0 20px 48px;
        cursor: pointer;
    }

    .item {
        margin: 15px 0 0 45px;
    }

    .item a {
        color: black!important;
    }

    .single-item {
        padding: 10px 0 10px 20px;
        border-left: 10px solid transparent;
        //margin-bottom: 15px;

        a {
            color: black!important;
        }
        &:first-child {
            margin-top: 5px;
        }

        a:hover {
            text-decoration: none;
        }

        .go-back {
            cursor: pointer;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            color: #000000;
        }

        .go-back:hover {
            text-decoration: none;
            color: #000000;
        }
    }

    .arrow-img {
        margin-right: 5px;
    }

    .arrow-right {
        transform: rotate(-90deg);
    }

    .arrow-down {
        transform: unset
    }

    .side-item {
        border-left: 10px solid transparent;
        padding-left: 20px;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    .side-item-active {
        background: white;
        border-left: 10px solid #EC8B31;
    }
</style>