import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import axios from 'axios';
import store from './store/store'
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import Paginate from 'vuejs-paginate';
import {i18n} from './plugins/i18n';
import FlagIcon from 'vue-flag-icon'

import {interceptorsUtil, checkPermission, preventAuthUser} from './utils';
Vue.use(require('vue-moment'));
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.use({store});
Vue.component('paginate', Paginate)

Vue.use(VueMaterial);
Vue.use(FlagIcon);

interceptorsUtil();

router.beforeEach((to, from, next) => {
  const userInfo = localStorage.getItem('user');
  const userPermissions = userInfo ? JSON.parse(userInfo).permissions : '';


  checkPermission(to.meta.permissions, userPermissions, userInfo, next);

  preventAuthUser(to.path, userInfo, next)

});

import Pusher from 'pusher-js';

Pusher.logToConsole = true;

let backendBaseUrl = "http://tdd.loc/";

window.pusher = new Pusher("b7f9a6261ff04d6f5ad1", {
    cluster: "ap2",
    authEndpoint: `${backendBaseUrl}/broadcasting/auth`,
    auth: {
        headers: {
            "Authorization": "Bearer TOKEN",
        }
    }
});

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
  create() {

    axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response.status === 401) {
            this.$store.dispatch('logout')
          }
          return Promise.reject(error)
        }
    )

  }
}).$mount('#app')
