import axios from 'axios';

export const users = {

    state: () => ({
        usersList: [],
        user: {},
        deleteUserMessage: null,
        saveMessages: null,
        saveErrors: null,
    }),

    mutations: {
        setUsersList(state, data) {
            state.usersList = data
        },

        setUser(state, data) {
            state.user = data
        },

        setDeleteUser(state, data) {
            state.deleteUserMessage = data
        },

        setMessages(state, data) {
            state.saveMessages = data
        },

        setErrors(state, data) {
            state.saveErrors = data
        },

        clearErrorsMessages(state) {
            state.saveMessages = null;
            state.saveErrors = null;
        }
    },

    actions: {
        async getUsersList({commit}, paginationData = {}) {

            let requestData = {
                page: paginationData.page,
                filter: {
                    email: paginationData?.email
                },
                sort: {
                    sort: paginationData.sort,
                    sortBy: paginationData.sortBy
                },
                filterByRelation: {}
            };

            if (paginationData.role) {
                requestData.filterByRelation.roles = {name: paginationData.role};
            }

            return await axios({
                method: 'get',
                url: '/users/list',
                params: requestData
            })
                .then(data => {
                    commit('setUsersList', data.data.data)
                    commit('setReqPagination', data.data.meta)
                    return data.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async getUser({commit}, id) {
            return await axios
                .get(`/users/${id}/get`)
                .then(data => {
                    commit('setUser', data.data.data)
                })
        },

        async saveUser({commit}, data) {
            return await axios
                .post(data.url, data.data)
                .then(response => {
                    commit('setMessages', response.data.message)
                })
                .catch(error => {
                    commit('setErrors', error.response.data.errors)
                })
        },

        async getDeleteUser({commit}, id) {
            return await axios
                .delete(`/users/${id}/delete`)
                .then(data => {
                    commit('setDeleteUser', data)
                })

        }
    },

    getters: {
        usersList: state => state.usersList,
        user: state => state.user,
        saveMessages: state => state.saveMessages,
        saveErrors: state => state.saveErrors,
    }
}


