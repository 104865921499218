import header from "./eng/header";
import login from "./eng/login";
import userPage from "./eng/userPage";
import sidebar from "./eng/sidebar";
import requestPage from "./eng/requestPage";
import roles from "./eng/roles"
import client from './eng/client'
import reports from './eng/reports'
import logs from './eng/logs'

export default {
    ...header,
    ...login,
    ...userPage,
    ...sidebar,
    ...requestPage,
    ...roles,
    ...client,
    ...reports,
    ...logs
}