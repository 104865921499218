export default {
    menuData: [
        {name: 'Requests', path: '/requests', isActive: false, permissionName: 'requests_view'},
        {name: 'Users', path: '/users', isActive: false, permissionName: 'users_view'},
        {name: 'Roles', path: '/roles', isActive: false, permissionName: 'roles_view'},
        {name: 'Logs', path: '/logs', isActive: false,permissionName: 'tracking_log_view'},
        {name: 'Clients', path: '/clients', isActive: false, permissionName: 'clients_view'},
    ],
    subMenuData:
        {name: 'Requests by client', path: '/reports', isActive: false, permissionName: 'requests_view', class: "sub-list"},
    reports: 'Reports',
    back: 'Back to',
    edit: 'Edit',
    create: 'Create',
}