<template>
    <div class="grey darken-1 empty-layout">
        <router-view />
    </div>
</template>

<script>

    export default {
        name: 'empty-layout',
    }
</script>