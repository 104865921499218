import axios from "axios";
import router from "../router";
import store from '../store/store'

export const interceptorsUtil = function () {
    axios.interceptors.response.use(
        function (response) {
            // Call was successful, don't do anything special.
            return response;
        },
        function (error) {
            console.log(error.response.status);
            switch (error.response.status) {
                case 401: // Not logged in
                    store.dispatch('logout')
                    break;
                case 419: // Session expired
                case 503: // Down for maintenance
                    // Bounce the user to the login screen with a redirect back
                    router.push({path: '/login'});
                    break;
                case 500:
                    console.log('Oops, something went wrong!');
                    break;
                default:
                    // Allow individual requests to handle other errors
                    return Promise.reject(error);
            }
        }
    );
};

export const checkPermission =  function (permission, userPermissions, user, next) {
    let hasPermission = false;

    if (permission) {
        for (let i = 0; i < userPermissions.length; i++) {
            if (permission.includes(userPermissions[i])) {
                hasPermission = true;
                break
            }
        }
    }

    if (permission) {
        if (hasPermission) {
            next()
        } else {
            // next('/requests')
            if (user) {
                next('/requests');
            }
        }
    }

};

export const preventAuthUser = function (to, user, next) {
    if (user && to === '/login') {
        next('/requests');
    }

    if (user) {
        next()
    } else {
        if ( history.length === 1
            && to.includes('/reset-password')
        ){
            next();
        }
        else if (to !== '/login'
            && to !== '/forgot-password'
            && to.includes('/forgot-password') === false) {
            next('/login');
        }
        else {
            next();
        }



    }
};