import axios from 'axios';

export const roles = {

  state:() => ({
    rolesList: [],
  }),

  mutations: {
    setRolesList(state, data) {
      state.rolesList = data
    }
  },

  actions: {
    async getRolesList({commit}) {
      return await axios
        .get('/roles/list')
        .then(data => {
          commit('setRolesList', data.data.data)
        })

    }
  },

  getters: {
    rolesList: state => state.rolesList
  }
}


