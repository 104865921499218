export const userPermissions = {
    computed: {
        userPermissions() {
            let userData = localStorage.getItem('user')

            if(typeof userData === "undefined"
                || userData === null
                || userData.length <= 0) {
                return []
            }
            let userPermissions = JSON.parse(userData).permissions

            if(typeof userPermissions === "undefined"
                || userPermissions === null
                || userPermissions.length <= 0) {
                return []
            }

            return userPermissions
        }
    }
}