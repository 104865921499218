export default {
    login: 'Login',
    name: 'Name',
    password: 'Password',
    signIn: 'Sign In to your account',
    forgot: 'Forgot password?',
    resetPass: 'Reset Password',
    resetPassConfirm: 'Confirm password',
    reset: 'Reset',
    resetDesc: "We'll send password reset instructions to the email address associated with your account.",
    useRight: "This resource contains confidential information which is intended only for the use of the TKM company’s clients with authorized access. Any unauthorized access attempt will be prosecuted in accordance with the legislation of the Russian Federation and international law.",
}