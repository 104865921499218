export default {
    requests: 'Запросы',
    request: 'Запрос',
    title: 'Название',
    status: 'Статус',
    requestHeadData: [
        {title: 'Название', name: 'title', asc: false, desc: true},
        {title: 'Компания', name: 'client', asc: false, desc: true},
        {title: 'Физлицо', name: 'user', asc: false, desc: true},
        {title: 'Статус', name: 'status', asc: false, desc: true},
        {title: 'Приоритетность', name: 'priority', asc: false, desc: true},
        {title: 'Свойство', name: 'nature', asc: false, desc: true},
        {title: 'Создано в', name: 'created_at', asc: false, desc: true},
    ],
    reqDesc: 'Описание *',
    addAnswer: 'Добавить ответ',
    activityForm: 'Форма деятельности',
    addFiles: 'Добавить файлы',
    selectFile: 'Выбрать файл',
    comments: 'Комментарии',
    add: 'Добавить',
    addAComment: 'Добавить комментарий',
    send: 'Отправить',
    createdBy: 'Создан:',
    priority: 'Приоритетность:',
    nature: 'Свойство:',
    lastEdited: 'Последнее редактирование:',
    createData: "Дата создания",
    requestAnswerDate: 'Дата ответа на запрос:',
    verificationLevel: 'Уровень проверки',
    answer: 'Ответ',
    edit: 'Изменить',
    New: 'Новый',
    Processing: 'Обработка',
    Answered: 'Отвеченный',
    Approved: 'Одобренный',
    Accepted:'Принято',
    Closed: 'Закрытый',
    "Waiting for details": 'В ожидании деталей',
    "Waiting for answer": 'В ожидании ответа',
    'Новый': 'New',
    'Обработка': 'Processing',
    'Отвеченный': 'Answered',
    'Одобренный': 'Approved',
    'Принято' : 'Accepted',
    'Закрытый': 'Closed',
    'В ожидании деталей': 'Waiting for details',
    'В ожидании ответа': 'Waiting for answer',
    Standard: 'Стадартный',
    Sensitive: 'Особый',
    Urgent: 'Срочный',
    Priority: 'Приоритет',
    Nature: 'Свойства',
    Created: 'Создан',
    LastEdited: 'Последнее редактирование',
    Request: 'Дата запроса',
    Description: 'Описание',
    Verification: 'Уровень проверки',
    AddFile: 'Добавыть файлы',
    SelectFile: 'Выбрать файл',
    "[Empty]": '[Пустой]',
    "[Пустой]": '[Empty]',
    Organization: 'Организация',
    'Person level 1': 'Физлицо уровень 1',
    'Person level 2': 'Физлицо уровень 2',
    'Person level 3': 'Физлицо уровень 3',
    'Person level 4': 'Физлицо уровень 4',
    Financial: 'Финансовый',
    Other: 'Другой',
    'Другой': 'Other',
    'Организация': 'Organization',
    'Физлицо уровень 1': 'Person level 1',
    'Физлицо уровень 2': 'Person level 2',
    'Физлицо уровень 3': 'Person level 3',
    'Физлицо уровень 4': 'Person level 4',
    'Финансовый': 'Financial',
    "Create request": 'Создать запрос'
}