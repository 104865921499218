import header from "./ru/header";
import login from "./ru/login";
import userPage from "./ru/userPage";
import sidebar from "./ru/sidebar";
import requestPage from "./ru/requestPage";
import roles from "./ru/roles";
import client from './ru/client';
import reports from './ru/reports';
import logs from './ru/logs'

export default {
    ...header,
    ...login,
    ...userPage,
    ...sidebar,
    ...requestPage,
    ...roles,
    ...client,
    ...reports,
    ...logs
}