export default {
    "Client: create": 'Клиент: создать',
    "Client: delete": 'Клиент: удалить',
    "Client: update": 'Клиент: редактировать',
    "Client: view all": 'Клиент: смотреть все',
    "permissions.document_create": 'разрешения. создать_документ',
    "permissions.document_delete": 'разрешения. удалить_документ',
    "permissions.document_update": 'разрешения. редактировать__документ',
    "permissions.document_view": 'разрешения. смотреть_документ',
    "permissions.requests_answer_create": 'разрешения.запросы_ответить_создать',
    "permissions.requests_answer_delete": 'разрешения.запросы_ответить_удалить',
    "permissions.requests_answer_update": 'разрешения.запросы_ответить_редактировать',
    "permissions.requests_answer_view": 'разрешения.запросы_ответить_смотреть',
    "permissions.requests_comment_create": 'разрешения.запросы_комментарий_создать',
    "permissions.requests_comment_delete": 'разрешения.запросы_комментарий_удалить',
    "permissions.requests_comment_update": 'разрешения.запросы_комментарий_редактировать',
    "permissions.requests_comment_view": 'разрешения.запросы_комментарий_смотреть',
    "Requests: create": 'Запросы: создать',
    "Requests: delete": 'Запросы: удалить',
    "Requests: update": 'Запросы: редактировать',
    "Requests: view": 'Запросы: смотреть',
    "Roles: create": 'Роль: создать',
    "Roles: delete": 'Роль: удалить',
    "Roles: update": 'Роль: редактировать',
    "Roles: view": 'Роль: смотреть',
    "User personal cabinet: update data": 'Личный кабинет пользователя: обновить данные',
    "User personal cabinet: view": 'Личный кабинет пользователя: смотреть',
    "Users: create": 'Пользователи: создать',
    "Users: delete": 'Пользователи: удалить',
    "Users: update": 'Пользователи: редактировать',
    "Users: view": 'Пользователи: смотреть',
    "admin": 'админ',
    "client": 'клиент',
    "manager": 'менеджер',
    "Edit Role": 'Изменить роль',
    "Role permissions": 'Разрешения роли',
    "Cannot change admin role": 'Не возможно изменить роль администратора',
    name: 'Имя',
    'Access rules': 'Правила доступа',
    Role: 'Роль',
    accessRoleText: 'Поэтому, отвергнув любого предусмотрительного чиновника, нас можно будет избежать. Кто заслуживает делать то, что называется. Какая-то боль отвергает ее, и если те, кто восхваляет ее, не откажутся от нее, они ее отвергнут.',
    Roles: 'Роли',
    Actions: 'Действия',
    roles: 'Ролам',
    clients: 'Клиентам',
}