export default {
    Reports: 'Отчеты',
    Title: 'Заголовок',
    Nature: 'Свойства',
    'Creation date': 'Дата создания',
    'Answer date': 'Дата ответа',
    'Number of requests': 'Количество запросов',
    'Person level': 'Физлицо уровень',
    Organization: 'Организация',
    Financial: 'Финансовый',
    Other: 'Другой',
    reportModalText: '',
    "All clients": "Все клиенты",
    "From date": 'Oт даты',
    "To date": 'До даты'
}