export default {
    requests: 'Requests',
    request: 'Request',
    title: 'Title',
    status: 'Status',
    requestHeadData: [
        {title: 'Title', name: 'title', asc: false, desc: true},
        {title: 'Company', name: 'client', asc: false, desc: true},
        {title: 'Person', name: 'user', asc: false, desc: true},
        {title: 'Status', name: 'status', asc: false, desc: true},
        {title: 'Priority', name: 'priority', asc: false, desc: true},
        {title: 'Nature', name: 'nature', asc: false, desc: true},
        {title: 'Created at', name: 'created_at', asc: false, desc: true},
    ],
    reqDesc: 'Description *',
    addAnswer: 'Add answer',
    activityForm: 'Activity form',
    addFiles: 'Add files',
    selectFile: 'Select file',
    comments: 'Comments',
    add: 'Add',
    addAComment: 'Add a comment',
    send: 'Send',
    createdBy: 'Created by:',
    priority: 'Priority:',
    nature: 'Nature:',
    lastEdited: 'Last edited:',
    createData: "Created date",
    requestAnswerDate: 'Request answer date:',
    verificationLevel: 'Verification level',
    answer: 'Answer',
    edit: 'Edit',
    New: 'New',
    Processing: 'Processing',
    Answered: 'Answered',
    Approved: 'Approved',
    Accepted:'Accepted',
    Closed: 'Closed',
    'Новый': 'New',
    'Стадартный': 'Standard',
    'Особый': 'Sensitive',
    'Срочный': 'Urgent',
    "Waiting for details": 'Waiting for details',
    "Waiting for answer": 'Waiting for answer',
    Standard: 'Standard',
    Sensitive: 'Sensitive',
    Urgent: 'Urgent',
    Priority: 'Priority',
    Nature: 'Nature',
    Created: 'Created by',
    Request: 'Request date',
    Description: 'Description',
    Verification: 'Verification level',
    AddFile: 'Add files',
    SelectFile: 'Select file',
    LastEdited: 'Last edited',
    Organization: 'Organization',
    'Person level 1': 'Personal level 1',
    'Person level 2': 'Personal level 2',
    'Person level 3': 'Personal level 3',
    'Person level 4': 'Personal level 4',
    Financial: 'Financial',
    Other: 'Other',
    "[Empty]": "[Empty]",
    'Организация': 'Organization',
    'Физлицо уровень 1': 'Person level 1',
    'Физлицо уровень 2': 'Person level 2',
    'Физлицо уровень 3': 'Person level 3',
    'Физлицо уровень 4': 'Person level 4',
    'Финансовый': 'Financial',
    // 'Другое': 'Other'
}