import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'Home',
      meta: {layout: 'main'},
      component: () => import('../views/requests/List'),
    },
    {
      path: '/roles',
      name: 'roles-list',
      meta: {layout: 'main', permissions: ['roles_view']},
      component: () => import('../views/roles/List'),
    },
    {
      path: '/roles/create',
      name: 'roles-create',
      meta: {layout: 'main'},
      component: () => import('../views/roles/Form'),
    },
    {
      path: '/roles/edit/:id',
      name: 'roles-edit',
      meta: {layout: 'main'},
      component: () => import('../views/roles/Form'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: {layout: 'empty'},
        component: () => import('../views/auth/Login')
    },
    {
      path: '/clients',
      name: 'clients-list',
      meta: {layout: 'main', permissions: ['clients_view']},
      component: () => import('../views/clients/List'),
    },
    {
      path: '/clients/create',
      name: 'clients-create',
      meta: {layout: 'main', permissions: ['client_create', 'client_update']},
      component: () => import('../views/clients/Form'),
    },
    {
      path: '/clients/edit/:id',
      name: 'clients-edit',
      meta: {layout: 'main', permissions: ['client_create', 'client_update']},
      component: () => import('../views/clients/Form'),
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        meta: {layout: 'empty'},
        component: () => import('../views/auth/ForgotPassword'),
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {layout: 'empty'},
        component: () => import('../views/auth/ResetPassword'),
    },
    {
        path: '/requests',
        name: 'requests-list',
        meta: {layout: 'main'},
        component: () => import('../views/requests/List'),
    },
    {
        path: '/reports',
        name: 'reports-list',
        meta: {layout: 'main'},
        component: () => import('../views/reports/List'),
    },
    {
        path: '/requests/create',
        name: 'requests-form-create',
        meta: {layout: 'main'},
        component: () => import('../views/requests/Create'),
    },
    {
        path: '/requests/edit/:id',
        name: 'requests-form-edit',
        meta: {layout: 'main'},
        component: () => import('../views/requests/Edit'),
    },
    {
      path: '/users',
      name: 'user-list',
      meta: {layout: 'main', permissions: ['users_view']},
      component: () => import('../views/users/List'),
    },
    {
      path: '/users/create',
      name: 'users-create',
      meta: {layout: 'main', permissions: ['users_create', 'users_update']},
      component: () => import('../views/users/Form'),
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      meta: {layout: 'main', permissions: ['users_create', 'users_update']},
      component: () => import('../views/users/Form'),
    },
    {
        path: '/logs',
        name: 'logs',
        meta: {layout: 'main'},
        component: () => import('../views/logs/List')
    },
];


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router