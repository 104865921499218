<template>
  <div id="app">
      <component :is="layout" class="app">
          <router-view/>
      </component>
  </div>
</template>

<script>
    import MainLayout from "@/layouts/MainLayout";
    import EmptyLayout from "./layouts/EmtyLayout";

export default {
    name: 'App',
    computed: {
        layout() {
            return (this.$route.meta.layout || 'empty') + '-layout'
        }
    },
    components: {
        MainLayout,
        EmptyLayout,
    },
}
</script>

<style>
    .app {
        display: flex;
        height: auto;
        min-height: 100vh;
    }

    @font-face {
      font-family: "Inter";
      src: local("Inter"),
      url(./assets/fonts/Inter-VariableFont_slnt,wght.ttf) format("truetype");
    }
    * {
      font-family: "Inter";
      margin: 0;
      padding: 0;
    }
</style>
