export default {
    login: 'Вход',
    name: 'Имя',
    password: 'Пароль',
    signIn: 'Войти в свой аккаунт',
    forgot: 'Забыли пароль?',
    resetPass: 'Сбросить пароль',
    resetPassConfirm: 'Подтвердите пароль',
    reset: 'Сброс',
    resetDesc: "Мы отправим инструкции о сбросе пароля на электронный адрес, привязанный к Вашему аккаунту",
    useRight: 'Этот ресурс содержит конфиденциальную информацию, предназначенную для использования только клиентами компании WORLD, имеющих авторизованный доступ. Любая попытка несанкционированного доступа будет преследоваться в соответствии с законодательством Российской Федерации и международным правом.'
}