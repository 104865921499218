export default {
    menuData: [
        {name: 'Запросы', path: '/requests', isActive: false, permissionName: 'requests_view'},
        {name: 'Пользователи', path: '/users', isActive: false, permissionName: 'users_view'},
        {name: 'Роли', path: '/roles', isActive: false, permissionName: 'roles_view'},
        {name: 'Журналы', path: '/logs', isActive: false , permissionName: 'tracking_log_view'},
        {name: 'Клиенты', path: '/clients', isActive: false, permissionName: 'clients_view'},
    ],
    subMenuData:
        {name: 'Запросы от клиента', path: '/reports', isActive: false, permissionName: 'requests_view', class: "sub-list"},
    reports: 'Отчеты',
    back: 'Вернуться к',
    edit: 'Изменить ',
}