export default {
    logs:'Logs',
    headData: [
        {title: 'Date/time', name: 'created_at', asc: false, desc: true},
        {title: 'Subject user', name: 'subject_user_id', asc: false, desc: true},
        {title: 'Action type', name: 'action_type', asc: false, desc: true},
        {title: 'Object type', name: 'object_type', asc: false, desc: true},
        {title: 'Object instance', name: 'object_instance_id', asc: false, desc: true},
    ]
}

