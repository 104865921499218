import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from "@/router";

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const token = JSON.parse(localStorage.getItem('user'))?.token
axios.defaults.headers.common.Authorization = `Bearer ${token}`

export const auth = {


    state: () => ({
        userInfo: null,
        userData: null,
        clientsList: [],
        forgotPasswordMessage: null,
        lastEditorInfo: null,
        reqPagination: null,
        reqPaginationData: null
    }),

    mutations: {
        setUserData(state, userData) {
            state.userInfo = userData;
            localStorage.setItem('user', JSON.stringify(userData));
            axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
        },
        setReqPagination(state, data) {
            state.reqPagination = data;
        },

        clearUserData() {
            localStorage.removeItem('user')
            axios.defaults.headers.common.Authorization = `Bearer`
        },
        setForgotPassword(state, data) {
            state.forgotPasswordMessage = data
        },


        setUserInfo(state, data) {
            state.userData = data;
        },
        setClientsList(state, data) {
            state.clientsList = data;
        },
        setLastEditor(state, data) {
            state.lastEditorInfo = data
        }

    },

    actions: {
        async login({commit}, credentials) {
            return await axios
                .post(process.env.VUE_APP_API_URL + '/auth/login', credentials)
                .then(({data}) => {
                    commit('setUserData', data)
                    return data
                })
                .catch(err => {
                    return err.response.data
                })
        },

        logout(context) {
            let userData = localStorage.getItem('user')

            if (typeof userData !== "undefined"
                && userData !== null) {
                axios
                    .post('/auth/logout')
                    .then(() => {
                        context.commit('clearUserData')
                    })
                    .catch(({error}) => {
                        console.log('logout', error)
                    }).finally(() => {
                    router.push({path: '/login'})
                });
            }
        },

        async forgotPassword({commit}, data) {
            return await axios
                .post('/auth/password/forgot', data)
                .then(data => {
                    commit('setForgotPassword', data)
                })
                .catch(err => {
                    console.log(err)
                })
        },


        getUserInfo({commit}) {
            let data = JSON.parse(localStorage.getItem('user'))?.user
            data && commit('setUserInfo', data)
        },

        async getClientsList({commit}, paginationData = {}) {
            let requestData = {
                page: paginationData.page,
                filter: {
                    name: paginationData?.name
                },
                sort: {
                    sort: paginationData.sort,
                    sortBy: paginationData.sortBy
                },
                filterClientsByManagerRole: {
                    manager_id: paginationData.managerId
                },
                filterByRelation: {}
            };
            return await axios({
                method: 'get',
                url: '/clients/list',
                params: requestData
            })
                .then((data) => {
                    commit('setClientsList', data.data.data)
                    commit('setReqPagination', data.data.meta)
                    return data.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },


    },
    getters: {
        isLogged: state => !!state.userInfo,
        clientsList: state => state.clientsList,
        userData: state => state.userData,
        forgotPasswordMessage: state => state.forgotPasswordMessage.data,
        lastEditorInfo: state => state.lastEditorInfo,
    }
}


