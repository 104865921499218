export default {
    "Edit Client": 'Редактировать клиента',
    "Create Client": 'Создать клиента',
    'Active': 'Активный',
    'Inactive': 'Неактивный',
    Name: 'Имя',
    Status: 'Статус',
    'Created at': 'Создано',
    'Updated at': 'Oбновлено',
    Clients: 'Клиенты',
    clientManager:'Менеджер',
}