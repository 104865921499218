export default {
    users: 'Users',
    email: 'Email',
    role: 'Role',
    delete: 'Delete',
    create: 'Create',
    actions: 'Actions',
    userHeadData:  [
        {title: 'Name', name: 'name', asc: false, desc: true},
        {title: 'Role', name: 'role_name', asc: false, desc: true},
        {title: 'Email', name: 'email', asc: false, desc: true},
        {title: 'Created at', name: 'created_at', asc: false, desc: true},
        {title: 'Updated at', name: 'updated_at', asc: false, desc: true},
    ],
    language: 'Language',
    client: 'Client',
    save: 'Save',
    cancel: 'Cancel',
    noPermission: 'There is no permission',
    createUser: 'Create User',
    editUser: 'Edit User',
    permissions: 'Permissions',
    "Пользователь с таким именем уже существует в базе данных." : 'A user with this Name already exists in the database.',
    "Электронная почта должна быть действительным адресом электронной почты." : 'The email must be a valid email address.',
    clientUser:'Client'

}